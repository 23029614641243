import { Switch, Route } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { ImportContextProvider } from "../contexts/import.context";
import lightTheme from "../styles/light-theme";
import PrivateRoutes from "./PrivateRoutes";
import CurrentRoutes from "./CurrentRoutes";
import GlobalStyles from "../styles/global";
import {
  LoginView,
  NotFoundView,
  /* PdvView,
  CadastrarEmpresaView, */
  LoadingView,
  CadastrarContadorView,
} from "../views";

const Routes = () => {
  const getCurrentRoutes = () => {
    return CurrentRoutes;
  };
  return (
    <ThemeProvider theme={lightTheme}>
      <ImportContextProvider>
        <GlobalStyles themeMode={lightTheme} />
        <Switch>
          <Route
            exact
            render={(matchProps) => <LoadingView {...matchProps}></LoadingView>}
            path="/"
          />
          <Route
            render={(matchProps) => <LoginView {...matchProps}></LoginView>}
            path="/login"
          />
          <Route
            render={(matchProps) => (
              <CadastrarContadorView {...matchProps}></CadastrarContadorView>
            )}
            path="/cadastros/contador/:token"
          />
          {getCurrentRoutes().map((route) => (
            <PrivateRoutes
              key={route.path}
              component={route.component}
              layout={route.layout}
              path={route.path}
              title={route.title}
              exact={route.exact}
            />
          ))}
          <Route
            render={(matchProps) => (
              <NotFoundView {...matchProps}></NotFoundView>
            )}
          />
        </Switch>
      </ImportContextProvider>
    </ThemeProvider>
  );
};

export default Routes;
